import React from 'react';
import Header from './header';
import './layout.css';

class Layout extends React.Component {
    componentDidMount() {
        console.log(
            '%c%s',
            'color: #0094ff; font-size: 18px;',
            'Made with ❤ by Arvind Kumar (https://arvind.io)\nFor more info, see /humans.txt'
        );
    }

    render() {
        return (
            <>
                <div
                    className={['layout', this.props.index ? 'index' : '']
                        .join(' ')
                        .trim()}
                >
                    <Header
                        active={this.props.active}
                        title={this.props.title}
                        index={this.props.index}
                    />
                    <div className="container">{this.props.children}</div>
                </div>
                <div
                    className={[
                        'footer-spacing',
                        this.props.index ? 'hide' : ''
                    ]
                        .join(' ')
                        .trim()}
                >
                    <iframe
                        title="footer"
                        id="footer"
                        src="https://footer.arvind.io"
                        frameBorder="0"
                    ></iframe>
                </div>
            </>
        );
    }
}

export default Layout;
