import { Link } from 'gatsby';
import React from 'react';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSlider: false
        };
    }

    hideSlider = () => {
        this.setState({
            showSlider: false
        });
    };

    render() {
        const active = this.props.active;
        const title = this.props.title;
        return (
            <div
                className={['header', this.props.index ? 'index' : '']
                    .join(' ')
                    .trim()}
            >
                <div className="title">
                    <a href="/">
                        mallik <span className="title-company">ideogram</span>
                    </a>
                </div>
                <div
                    className="menu"
                    onClick={() => {
                        this.setState({
                            showSlider: !this.state.showSlider
                        });
                    }}
                >
                    <i
                        className={
                            this.state.showSlider ? 'fa fa-times' : 'fa fa-bars'
                        }
                    ></i>
                </div>
                <div className="links">
                    <Link
                        className={[
                            'link',
                            active === 'Gallery' &&
                            title !== 'Creative' &&
                            title !== 'Videos'
                                ? 'active'
                                : ''
                        ]
                            .join(' ')
                            .trim()}
                        to="/"
                    >
                        Gallery
                    </Link>
                    <Link
                        className={[
                            'link',
                            title === 'Creative' ? 'active' : ''
                        ]
                            .join(' ')
                            .trim()}
                        to="/creative"
                    >
                        Creative
                    </Link>
                    <Link
                        className={['link', title === 'Videos' ? 'active' : '']
                            .join(' ')
                            .trim()}
                        to="/videos"
                    >
                        Videos
                    </Link>
                    <Link
                        className={['link', active === 'About' ? 'active' : '']
                            .join(' ')
                            .trim()}
                        to="/about-contact"
                    >
                        About
                    </Link>
                    <Link
                        className={[
                            'link',
                            active === 'Clients' ? 'active' : ''
                        ]
                            .join(' ')
                            .trim()}
                        to="/clients-testimonials"
                    >
                        Clients
                    </Link>
                    <Link
                        className={[
                            'link',
                            active === 'Testimonials' ? 'active' : ''
                        ]
                            .join(' ')
                            .trim()}
                        to="/clients-testimonials#testimonials"
                    >
                        Testimonials
                    </Link>
                    <a className="link" href="mailto:mallik@mallik.in">
                        mallik@mallik.in
                    </a>
                    <a className="link" href="tel:+919845169059">
                        +91-9845169059
                    </a>
                    <div className="social-icons">
                        <a
                            className="link social"
                            href="https://www.instagram.com/mallikarjun_katakol/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="fa fa-instagram"></i>
                        </a>
                        <a
                            className="link last-link social"
                            href="https://www.facebook.com/mallikphoto"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="fa fa-facebook-square"></i>
                        </a>
                    </div>
                </div>
                <div
                    className={[
                        'menu-slider',
                        this.state.showSlider ? 'show' : ''
                    ]
                        .join(' ')
                        .trim()}
                >
                    <div className="close-menu" onClick={this.hideSlider}>
                        <i className="fa fa-times"></i>
                    </div>
                    <div className="title">
                        <a href="/">
                            mallik{' '}
                            <span className="title-company">ideogram</span>
                        </a>
                    </div>
                    <div className="links" onClick={this.hideSlider}>
                        <Link
                            className={[
                                'link',
                                active === 'Gallery' &&
                                title !== 'Creative' &&
                                title !== 'Videos'
                                    ? 'active'
                                    : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/"
                        >
                            Gallery
                        </Link>
                        <Link
                            className={[
                                'link',
                                title === 'Creative' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/creative"
                        >
                            Creative
                        </Link>
                        <Link
                            className={[
                                'link',
                                title === 'Videos' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/videos"
                        >
                            Videos
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'About' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/about-contact"
                        >
                            About
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'Clients' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/clients-testimonials"
                        >
                            Clients
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'Testimonials' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/clients-testimonials#testimonials"
                        >
                            Testimonials
                        </Link>
                        <a className="link" href="mailto:mallik@mallik.in">
                            mallik@mallik.in
                        </a>
                        <a className="link" href="tel:+919845169059">
                            +91-9845169059
                        </a>
                        <div className="social-icons">
                            <a
                                className="link social"
                                href="https://www.instagram.com/mallikarjun_katakol/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="fa fa-instagram"></i>
                            </a>
                            <a
                                className="link last-link social"
                                href="https://www.facebook.com/mallikphoto"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="fa fa-facebook-square"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
