import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, title }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
        >
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <meta name="robots" content="index, follow" />
            <meta name="referrer" content="origin" />
            <meta
                name="title"
                content={`${title} | ${site.siteMetadata.title}`}
            />
            <meta name="description" content={metaDescription} />
            <meta
                name="og:title"
                content={`${title} | ${site.siteMetadata.title}`}
            />
            <meta name="og:image" content="/static/images/preview.png" />
            <meta name="og:description" content={metaDescription} />
            <meta name="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content={`${title} | ${site.siteMetadata.title}`}
            />
            <meta
                name="twitter:image"
                content="https://mallik.in/static/images/preview.png"
            />
            <meta name="twitter:description" content={metaDescription} />
            <meta
                itemProp="name"
                content={`${title} | ${site.siteMetadata.title}`}
            />
            <meta itemProp="image" content="/static/images/preview.png" />
            <meta itemProp="description" content={metaDescription} />
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    description: ``
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    title: PropTypes.string.isRequired
};

export default SEO;
